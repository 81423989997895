import axios from 'axios';
import type { Event, PaginatedList } from 'core';
import type { FC } from 'react';
import usePromise from 'react-use-promise';
import uri from 'uri-tag';
import { useAuthenticated } from '../../auth/auth-provider.js';
import { EventListPageView } from './view.js';

const Controller: FC = () => {
  const { person } = useAuthenticated();

  const events = usePromise(async () => {
    const { data } = await axios.get<PaginatedList<Event.WithCourses>>(
      uri`/api/v1/events`,
      {
        params: { sort: '-start' },
      },
    );
    return data;
  }, []);

  return EventListPageView({
    events,
    person,
  });
};

export default Controller;
